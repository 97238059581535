<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
            >
                <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                    <div class="row">
                        <div class="col-6">
                            <h4>
                                <router-link :to="{name:'dashboard'}">
                                    <i class="fas fa-arrow-left"></i>
                                </router-link>Back
                            </h4>
                        </div>
                        <div class="col-6 text-right"></div>
                        <div class="col-3">
                            <input
                                    type="text"
                                    v-model="search.first_name"
                                    placeholder="First Name"
                                    class="form-control"
                            />
                        </div><div class="col-3">
                        <input
                                type="text"
                                v-model="search.last_name"
                                placeholder="Last Name"
                                class="form-control"
                        />
                    </div>
                        <div class="col-3">
                            <input type="text" v-model="search.email" placeholder="Email" class="form-control" />
                        </div>
                        <div class="col-3">
                            <input type="text" v-model="search.phone" placeholder="phone" class="form-control" />
                        </div>

                        <div class="col-3">
                            <button class="btn btn-primary" @click="getMessages">Search</button>
                            <button class="btn btn-primary ml-2" @click="_reset">Reset</button>
                        </div>
                        <div class="col-12">
                            <div class="">
                                <table class="table table-stripe">
                                    <thead>
                                    <th>Full Name</th>
                                    <th>email</th>
                                    <th>phone</th>
                                    <th>Subject</th>
                                    <th>Action</th>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(message, index) of contacts" :key="index">
                                        <td>{{message.first_name}} {{message.last_name}}</td>
                                        <td>{{message.email}}</td>
                                        <td>{{message.phone}}</td>
                                        <td>{{message.subject}}</td>

                                        <!--                <td>   <i-->
                                        <!--                        class="fas fa-circle"-->
                                        <!--                        :class="message.is_active?'text-success':'text-danger'"-->
                                        <!--                ></i></td>-->
                                        <td>
                                        <span @click="openDialog(message.id)" class="cursor-pointer" title="view">
                                          <i class="fas fa-eye"></i>
                                        </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ContactService from "@/core/services/contact/ContactService";


    const contact = new ContactService();

    export default {
        name: "Contacts-messages",
        components: {
        },
        data() {
            return {
                contacts: [],
                message_id: null,
                search: {}
            };
        },
        mounted() {
            this.getMessages();
        },
        methods: {
            getMessages() {
                contact.paginate(this.search).then(response => {
                    this.contacts = response.data.data;
                });
            },
            openDialog(id = null) {
                this.message_id = id;
                this.$refs["message"].showModal();
            },
            closeDialog(id) {
                this.message_id = id;
                this.$refs["message"].hideModal();
                this.getMessages();
            },
            _reset() {
                this.search = {};
                this.getMessages();
            }
        }
    };
</script>
